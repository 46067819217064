import React, { lazy } from 'react';
import Authenticated from 'src/components/Authenticated';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import Loader from 'src/components/Loader';
import { UserTypeLabels } from 'src/constants';

const BusinessListing = Loader(lazy(() => import('../content/business-onboarding/BusinessListing')));
const OnboardingPage = Loader(lazy(() => import('../content/onboarding')));
// business onboarding pages
const BusinessOnboarding = Loader(lazy(() => import('../content/business-onboarding')));
const BusinessProfile = Loader(lazy(() => import('../content/business-profile-new')));
const LoggedInBusinessProfile = Loader(lazy(() => import('../content/business-profile-new/loggedInUser')));

const AllReviews = Loader(lazy(() => import('../content/all-reviews')));

// import reviews module
const ImportReviews = Loader(lazy(() => import('../content/import-review/importReview')));

// request revies page
const ListAllProjectsWithFreelancer = Loader(
	lazy(() => import('../content/projectOld/components/listAllProjectsWithFreelancer'))
);
const ReviewFreelancerView = Loader(lazy(() => import('../components/ReviewFreelancerCard')));

// freelancer new profile page (will be renamed later)
const FreelancerProfile = Loader(lazy(() => import('../content/freelancer-profile')));
const OnboardingForms = Loader(lazy(() => import('../content/onboarding-flow/forms/index')));

const ProfileRoutes = [
	// Onboarding
	{
		path: 'onboarding',
		element: (
			<Authenticated isInOnboardingPage>
				<OnboardingPage />
			</Authenticated>
		),
	},
	{
		path: 'edit-business-profile',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.BUSINESS]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':step',
				element: <BusinessOnboarding isEditProfile />,
			},
		],
	},
	{
		path: 'edit-profile',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <OnboardingForms />,
			},
		],
	},
	{
		path: 'edit-profile',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.FREELANCER, UserTypeLabels.INTERN]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':step',
				element: <OnboardingPage isEditProfile />,
			},
		],
	},

	{
		path: 'import-reviews',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.FREELANCER, UserTypeLabels.INTERN]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <ImportReviews />,
			},
		],
	},
	// review freelancer
	{
		path: 'review-freelancer',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':taskId/:employeeUserId',
				element: <ReviewFreelancerView />,
			},
		],
	},
	{
		path: 'reviews/:userId/:profileId',
		element: <AllReviews />,
	},
	{
		path: 'business-listing',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.BUSINESS]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <BusinessListing />,
			},
		],
	},

	// business onboarding related pages

	{
		path: 'business-onboarding',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.BUSINESS]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <BusinessOnboarding />,
			},
		],
	},

	// business profile
	{
		path: 'business-profile',
		children: [
			{
				path: '',
				element: <LoggedInBusinessProfile />,
			},
			{
				path: ':profileId',
				element: <BusinessProfile />,
			},
		],
	},




	{
		path: 'profile',
		element: <CollapsedSidebarLayout />,
		children: [
			{
				path: '',
				element: <FreelancerProfile />,
			},
			{
				path: ':profileId',
				element: <FreelancerProfile />,
			},
		],
	},
	// request review page related
	{
		path: 'review-user',
		children: [
			{
				path: ':employeeUserId',
				element: <ListAllProjectsWithFreelancer />,
			},
		],
	},
];

export default ProfileRoutes;
