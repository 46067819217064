import React from 'react';

const CustomErrorIcon = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx="12" cy="12.48" rx="12" ry="12.48" fill="#FFB406" />
      <path
        d="M13.174 6.24364L12.9865 14.4596H11.1569L10.9751 6.24364H13.174ZM12.0717 18.005C11.727 18.005 11.4315 17.8838 11.1853 17.6414C10.9429 17.3989 10.8217 17.1035 10.8217 16.755C10.8217 16.4141 10.9429 16.1224 11.1853 15.88C11.4315 15.6376 11.727 15.5164 12.0717 15.5164C12.4088 15.5164 12.7005 15.6376 12.9467 15.88C13.1967 16.1224 13.3217 16.4141 13.3217 16.755C13.3217 16.9861 13.263 17.1963 13.1456 17.3857C13.0319 17.5751 12.8804 17.7266 12.691 17.8402C12.5054 17.9501 12.299 18.005 12.0717 18.005Z"
        fill="white"
      />
    </svg>
  );
};

export default CustomErrorIcon;
