import React from 'react';
import { Typography, Tooltip, Stepper, Step, StepButton, StepLabel } from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ErrorIcon from '@mui/icons-material/Error';
import CustomErrorIcon from './custom-error';



const VideoInterviewHeader = ({ title }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography variant="h4" sx={{ mr: 1 }}>
				{title ? title : 'Video Interview'}
			</Typography>
			<Tooltip
				arrow
				componentsProps={{
					tooltip: {
						sx: {
							backgroundColor: 'white',
							color: 'black',
						},
					},
				}}
				title={
					<>
						<p>Click on any number to jump to that question.</p>
						<Stepper orientation="vertical">
							<Step active={true}>
								<StepButton>
									<StepLabel>Active Question.</StepLabel>
								</StepButton>
							</Step>
							<Step>
								<StepButton>
									<StepLabel>
										<p style={{ color: '#223354' }}>Unanswered Question.</p>
									</StepLabel>
								</StepButton>
							</Step>
							<Step completed={true}>
								<StepButton>
									<StepLabel>Answered Question.</StepLabel>
								</StepButton>
							</Step>
							<Step>
								<StepButton>
									<StepLabel StepIconComponent={CustomErrorIcon}>
										Attention Needed.
									</StepLabel>
								</StepButton>
							</Step>
						</Stepper>
					</>
				}
			>
				<InfoTwoToneIcon />
			</Tooltip>
		</div>
	);
};

export default VideoInterviewHeader;
